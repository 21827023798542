html {
  height: 100%;
}
body {
  height: inherit;
}
.frameBody {
  background-color: #1B3C69;
  padding: 0;
  width: 100%;
}
@font-face {
  font-family: Cunia;
  src: url(./fonts/Cunia/Cunia.otf);
  /* font-weight: bold; */
}
/* @font-face {
  font-family: hariboFontBold;
  src: url(./assets/fonts/VAGRoundedLTPro-Bold.otf);
  font-weight: bold;
} */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#root {
  height: inherit;
  background-color: #1B3C69;
  overflow: auto;
}
.App {
}
@keyframes cf3FadeInOut {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
